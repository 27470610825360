<template>
  <div id="content">
    <h1>Service Page</h1>
    <p>This is the Service page content.</p>
  </div>
</template>

<script>
export default {
  name: "ServicePage",
};
</script>

<style scoped>
#content {
  margin: 30px 30px;
}
</style>
