<template>
  <div id="content">
    <h1>Material Page</h1>
    <p>This is the Material page content.</p>
  </div>
</template>

<script>
export default {
  name: "MaterialsPage",
};
</script>

<style scoped>
#content {
  margin: 30px 30px;
}
</style>
